import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ApiService } from '../../services/api.service';
import Cookies from 'universal-cookie';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './../Swiper/GameHome.css';
import { Navigation } from 'swiper/modules';

import LoadingPage from '../Loading/LoadingPage';

import { useMediaQuery } from 'react-responsive'
import { useState } from 'react';

import SportsBg from './../../assets/images/bggames/sports.svg';

interface GameImageType {
    vertical: string;
    horizontal: string;
    banner: string;
}

interface GameType {
    id: string;
    provider: string;
    gameName: string;
    gameCategory: string;
    gameType: string[];
    image: GameImageType;
    status: string;
    rtp: number;
}

interface PropsType {
    game: GameType[],
    perpage: number | null | undefined
}

const GameList = ({ game, perpage }: PropsType) => {
    const cookies = new Cookies(null, { path: '/'});
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    });
    const [loading, setLoading] = useState<boolean>(false);

    const createRedirect = async (game_id: string, provider: string, tab: string) => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            if(!cookies.get('access_token'))
            {
                return navigate('/login');
            }

            const service = new ApiService();
            let getRedirectGameUrl = await service.getRedirectGameUrl(cookies.get('access_token'), game_id, provider, tab);

            if(getRedirectGameUrl.data.status_code !== 200)
            {
                getRedirectGameUrl = await service.getRedirectLoginUrl(cookies.get('access_token'));
            }

            const userAgent = navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

            if(safari)
            {
                window.location.href = getRedirectGameUrl.data.url;
            }
            else
            {
                const link = document.createElement('a');
                link.href = getRedirectGameUrl.data.url;
                link.target = '_blank';
                link.click();
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }
    
    return (
        <>
            {
                loading && <LoadingPage />
            }
            
            <Swiper
                slidesPerView={!perpage ? (game.length > 3 ? (isDesktopOrLaptop ? 4.5 : 3.5) : (game.length)) : perpage}
                spaceBetween={10}
                className="top-10-game-swiper"
            >
                {
                    game.slice(0, !perpage ? 5 : (game.length > 5 ? 5 : game.length)).map((element: GameType, index: number) => {
                        return (
                            <SwiperSlide key={index}>
                                <Box sx={
                                    {
                                        cursor: 'pointer'   
                                    }
                                } onClick={() => {
                                    (index + 1) === game.slice(0, 5).length ? navigate(`/game/${element.gameCategory.toLowerCase()}`) : createRedirect(element.id, element.provider, element.gameCategory)
                                }}>
                                    {
                                        game.length > 6 ? <Box style={{ position: 'relative', textAlign: 'center' }}>
                                            <img
                                                src={element.image.vertical}
                                                alt={element.gameName}
                                                style={{ 
                                                    filter: (index + 1) === game.slice(0, 5).length ? 'blur(3px)' : 'none', 
                                                    borderRadius: 10,
                                                    width: `100%`,
                                                    maxHeight: 200, 
                                                    display: 'block' 
                                                }}
                                            />
                                            <div style={{
                                                display: (index + 1) === game.slice(0, 5).length ? 'none' : 'block',
                                                position: 'absolute',
                                                bottom: '0',
                                                color: 'white',
                                                background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .8), rgba(0, 0, 0, 1))',
                                                transform: 'translate(-50%, 0%)',
                                                left: '50%',
                                                padding: '10px',
                                                borderRadius: '5px',
                                                maxWidth: '87%',
                                                minWidth: '50%',
                                                width: '100%'
                                            }}>
                                                <Typography fontSize={12} align='center'>
                                                    {
                                                        element.provider
                                                    }
                                                    &nbsp;
                                                    {
                                                        element.gameName
                                                    }
                                                </Typography>
                                            </div>
                                            <div style={{
                                                display: (index + 1) === game.slice(0, 5).length ? 'block' : 'none',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                color: 'white',
                                                // fontSize: '20px',
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                padding: '10px',
                                                borderRadius: '5px',
                                                minWidth: '50%'
                                            }}>
                                                <Typography fontSize={12} align='center'>
                                                    เพิ่มเติม
                                                </Typography>
                                            </div>
                                        </Box> : <Box style={{ position: 'relative', textAlign: 'center' }}>
                                            <img
                                                src={element.image.vertical}
                                                alt={element.gameName}
                                                style={{ 
                                                    borderRadius: 10, 
                                                    maxHeight: 250, 
                                                    width: '100%', 
                                                    display: 'block' 
                                                }}
                                            />
                                        </Box> 
                                    }
                                </Box>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </>
    )
}

export default GameList;