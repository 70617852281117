const RegisterBot = () => {
    return (
        <>
            <form method="post" action="https://lion777.apple855.com/Default8.aspx?lang=EN-GB">
                <input name="__EVENTTARGET" id="__EVENTTARGET" type="hidden" value="btnLogin" />
                <input name="__VIEWSTATE" id="__VIEWSTATE" type="hidden" value="/wEPDwUKLTM5ODkwMjE4NA8WAh4IUmVmZXJyZXIFE2h0dHBzOi8vdWZhMTExLmNvbS8WAmYPZBYGAgIPFgIeC3BsYWNlaG9sZGVyBR7guIrguLfguYjguK3guJzguLnguYnguYPguIrguYlkAgMPFgIfAQUY4Lij4Lir4Lix4Liq4Lic4LmI4Liy4LiZZAIEDw8WAh4EVGV4dAUh4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaZGQYAQUeX19Db250cm9sc1JlcXVpcmVQb3N0QmFja0tleV9fFgEFCWJ0bkxvZ2luMnMeii9lOZlDylIlusHF8exxbbei" />
                <input name="__VIEWSTATEGENERATOR" id="__VIEWSTATEGENERATOR" type="hidden" value="BE6BC141" />
                <input name="lstLang" id="lstLang" type="hidden" value="Default8.aspx?lang=EN-GB" />
                <input name="txtUserName" id="txtUserName" type="text" />
                <input name="password" id="password" type="text" />
                <button name="btn_submit" id="btn_submit" type="submit">
                    เปลี่ยนรหัสผ่าน
                </button>
            </form>
        </>
    )
}

export default RegisterBot;