import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
import SoundCashInEffect from './../assets/sound/cashprize.wav';

const socket = io('https://api.guayjub.com');
const SocketIO = () => {
    const cookies = new Cookies(null, {path: '/'});
    const PlayScbCashInEffect = () => {
        const audio = new Audio(SoundCashInEffect);
        audio.currentTime = 0;
        audio.volume = 0.5;
        audio.play();
    };

    useEffect(() => {
        socket.on('message', (msg) => {
            if(msg.type == 'scb_deposit_app')
            {
                if(cookies.get('access_token') && cookies.get('user') == msg.ufa_username)
                {
                    PlayScbCashInEffect();
                    Swal.fire({
                        icon: 'success',
                        title: 'แจ้งเตือน',
                        text: `ทำการฝากเงินจำนวน ${parseFloat(String(msg.amount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} บาทเรียบร้อยแล้วค่ะ`
                    });
                }
            }
        });

        return () => {
            socket.off('message');
        };
    }, []);

    return (
        <></>
    );
};

export default SocketIO;