import { Box, Grid, Typography } from "@mui/material";
import Logo from './../../assets/images/logo-circle.png';
import { Link } from "react-router-dom";
import MenuForMobile from "./MenuForMobile";
import { useEffect, useState } from "react";
import { FaLine } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import Cookies from "universal-cookie";
import { ApiService } from "../../services/api.service";
import { BiSolidCoinStack } from "react-icons/bi";
import { HiUserCircle } from "react-icons/hi";
import { HiOutlineRefresh } from "react-icons/hi";

const NewHeaderMain = () => {
    const cookies = new Cookies(null, {path: '/'});
    const [tabOpen, setTabOpen] = useState<boolean>(false);
    const [currentCredit, setCurrentCredit] = useState<number | null>(0);
    const [loadingCredit, setLoadingCredit] = useState<boolean>(false);

    useEffect(() => {
        if(cookies.get('access_token'))
        {
            getCredit();
        }
    }, []);

    const getCredit = async () => {
        if(!cookies.get('access_token'))
        {
            return;
        }
        else if(loadingCredit)
        {
            return;
        }

        setLoadingCredit(true);
        try {
            const service = new ApiService();
            const getCredit = await service.getCurrentCredit(cookies.get('access_token'));

            setCurrentCredit(getCredit.data.current_credit)
        } catch (err) {
            console.log(err);
        }
        setLoadingCredit(false);
    }

    return (
        <Grid container>
            <MenuForMobile tabOpen={tabOpen} setTabOpen={setTabOpen} />
            <Grid item xs={12} sx={{ bgcolor: '#750000' }}>
                {
                    !cookies.get('access_token') ? <Grid container px={2}>
                        <Grid item xs={5} sx={
                            {
                                display: {
                                    xs: 'flex',
                                    sm: 'flex',
                                    md: 'none',
                                    lg: 'none'
                                },
                                justifyContent: 'flex-start',
                                alignContent: `center`,
                                alignItems: `center`
                            }
                        }>
                            <Link to={'https://lin.ee/h3vvUJ2'} target="_blank">
                                <FaLine color="#fff" style={{ fontSize: 28, cursor: 'pointer', marginTop: 5 }} />
                            </Link>
                        </Grid>
                        <Grid item xs={5} sx={
                            {
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'flex',
                                    lg: 'flex'
                                },
                                justifyContent: 'space-around',
                                alignContent: `center`,
                                alignItems: `center`
                            }
                        }>
                            <Link to={'/'}>
                                <Typography sx={{ color: 'white', transition: 'all .2s', '&:hover': { color: '#ffe05f' } }}>
                                    หน้าแรก
                                </Typography>
                            </Link>
                            <Link to={'/register'}>
                                <Typography sx={{ color: 'white', transition: 'all .2s', '&:hover': { color: '#ffe05f' } }}>
                                    สมัครสมาชิก
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={2} display={`flex`} justifyContent={`center`}>
                            <Link to="/" style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={Logo} alt="logo" style={{ width: '80%', maxWidth: '75px', scale: '1.0', transition: 'all .2s' }} />
                            </Link>
                        </Grid>
                        <Grid item xs={5} sx={
                            {
                                display: {
                                    xs: 'flex',
                                    sm: 'flex',
                                    md: 'none',
                                    lg: 'none'
                                },
                                justifyContent: `flex-end`,
                                alignContent: `center`,
                                alignItems: `center`
                            }
                        }>
                            <HiMenu color="#fff" style={{ fontSize: 28, cursor: 'pointer' }} onClick={() => setTabOpen(!tabOpen)} />
                        </Grid>
                        <Grid item xs={5} sx={
                            {
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'flex',
                                    lg: 'flex'
                                },
                                justifyContent: 'space-around',
                                alignContent: `center`,
                                alignItems: `center`
                            }
                        }>
                            <Link to={'/promotions'}>
                                <Typography sx={{ color: 'white', transition: 'all .2s', '&:hover': { color: '#ffe05f' } }}>
                                    โปรโมชั่น
                                </Typography>
                            </Link>
                            <Link to={'https://lin.ee/h3vvUJ2'} target="_blank">
                                <Typography sx={{ color: 'white', transition: 'all .2s', '&:hover': { color: '#ffe05f' } }}>
                                    ติดต่อเรา
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid> : <Grid item xs={12}>
                        <Box display={`flex`} justifyContent={`space-between`} px={2}>
                            <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                                <Link to={!cookies.get('access_token') ? "/" : "/game/slot"} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={Logo} alt="logo" style={{ width: '80%', maxWidth: '75px', scale: '1.0', transition: 'all .2s' }} />
                                </Link>
                            </Box>
                            <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                                <Box py={.2} px={2} bgcolor={`black`} sx={{ borderRadius: '7px 0px 0px 7px' }} display={`flex`} alignContent={`center`} alignItems={`center`}>
                                    <HiUserCircle />&nbsp;{cookies.get('user') || "Unknown"}
                                </Box>
                                <Box py={.2} px={2} bgcolor={`rgba(0, 0, 0, .5)`} display={`flex`} alignContent={`center`} alignItems={`center`}>
                                    <BiSolidCoinStack color="#ffe05f" />
                                    {parseFloat(String(currentCredit)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    &nbsp;
                                    <HiOutlineRefresh className={`${loadingCredit ? `rotating` : ""}`} color="#white" onClick={getCredit} style={{ cursor: 'pointer' }} />
                                </Box>
                                <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} bgcolor={`rgba(0, 0, 0, .5)`} sx={{ borderRadius: '0px 7px 7px 0px' }}>
                                    <HiMenu color="#fff" style={{ fontSize: 28, cursor: 'pointer' }} onClick={() => setTabOpen(!tabOpen)} />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                }
                
            </Grid>
        </Grid>
    )
}

export default NewHeaderMain;