import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import { listPromotionsType } from "../../types";

import { Box, Grid, Typography, CircularProgress, Button, Container } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { FaLine } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import NavFooterMobile from "../Footer/NavFooterMobile";

import default_new_member from '../../assets/images/promotions/new_member.jpg';

import PromotionPopup from "./PromotionPopup";
import SocketIO from "../SocketIO";

const PromotionMain = () => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, {path: '/'});
    const [loaded, setLoaded] = useState<boolean>(false);
    const [promotionData, setPromotionData] = useState<listPromotionsType[] | null>(null);
    const [showPromotion, setShowPromotion] = useState<listPromotionsType | null>(null);

    useEffect(() => {
        if(!window.location.hostname.includes("localhost"))
        {
            if (window.location.hostname === `${process.env.REACT_APP_BASE_URL?.replace("https://", '').replace('http://', '')}`) {
                window.location.replace(`https://www.${process.env.REACT_APP_BASE_URL?.replace("https://", '').replace('http://', '')}` + window.location.pathname + window.location.search);
            }
        }

        getPromotionData();
    }, []);

    const getPromotionData = async () => {
        setPromotionData([]);
        setLoaded(true);
        
        try {
            const service = new ApiService();
            const getPromotionData = await service.getListPromotion();

            if(getPromotionData.data.status_code === 200)
            {
                setPromotionData(getPromotionData.data.promotions);
                setLoaded(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleShowPromotion = (promotion_id: number) => {
        const findIndex = promotionData?.findIndex((element: listPromotionsType) => element.id === promotion_id);
        if(findIndex === undefined || findIndex === -1 || promotionData === null)
        {
            return;
        }

        setShowPromotion(promotionData[findIndex]);
    }

    return (
        loaded ? <>
            <SocketIO />
            <Container sx={
                {
                    maxWidth: '768px !important',
                    padding: '0 !important'
                }
            }>
                <PromotionPopup promotion_detail={showPromotion} setShowPromotion={setShowPromotion} />
                <Box sx={
                    {
                        backgroundColor: 'rgba(0, 0, 0, .4)',
                        minHeight: `100dvh`,
                        height: '100%',
                        mb: 0,
                        pb: 0
                    }
                }>
                    <Box sx={
                        {
                            backgroundColor: '#750000',
                            px: 2,
                            py: 2.5,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }
                    }>
                        <Box display={`flex`} justifyContent={`flex-start`} alignContent={`center`} alignItems={`center`}>
                            <Link to={`/`} style={{ color: 'white' }}>
                                <Typography display={`flex`} justifyContent={`center`} alignContent={`center`}>
                                    <KeyboardArrowLeftIcon />กลับไปหน้าหลัก
                                </Typography>
                            </Link>
                        </Box>
                        <Box></Box>
                        <Box display={`flex`} justifyContent={`flex-end`} alignContent={`center`} alignItems={`center`}>
                            <Link to={`https://lin.ee/h3vvUJ2`} target="_blank">
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: 'rgba(0, 0, 0, .3)',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, .4)'
                                        }
                                    }
                                } size="small">
                                    <FaLine color="#04c854" />&nbsp;LINE@
                                </Button>
                            </Link>
                            <Button sx={
                                {
                                    mx: .5,
                                    borderRadius: 15,
                                    px: 2,
                                    backgroundColor: 'rgba(0, 0, 0, .3)',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, .4)'
                                    }
                                }
                            } size="small">
                                <FaTelegram color="#34aae4" />&nbsp;TELEGRAM
                            </Button>
                        </Box>
                    </Box>
                    <Box px={3} py={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography fontSize={20}>
                                    โปรโมชั่น
                                </Typography>
                            </Grid>
                            {
                                promotionData?.sort((a: listPromotionsType, b: listPromotionsType) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()).map((element: listPromotionsType, index: number) => {
                                    return (
                                        <Grid item xs={12} key={`promotion-${index}`}>
                                            <Box sx={
                                                {
                                                    backgroundColor: 'rgba(0, 0, 0, .5)',
                                                    transition: 'all .2s',
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        scale: '1.03',
                                                        borderRadius: 2
                                                    },
                                                    '&:hover img': {
                                                        borderRadius: 2
                                                    }
                                                }
                                            } onClick={() => handleShowPromotion(element.id)}>
                                                <Box>
                                                    <img src={`${process.env.REACT_APP_API_URL}/image/${element.poster_image}`} alt={`promotion_${element.name}`} style={{ maxWidth: '100%' }} />
                                                </Box>
                                                <Box pb={1}>
                                                    <Typography fontSize={22} align="center">
                                                        {element.name}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </Box>
            </Container>
            <NavFooterMobile />
        </>
        : <Box display={`flex`} sx={{ width: `100%`, height: `100%`, minHeight: `100dvh` }}>
            <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} width={`100%`}>
                <CircularProgress size={30} sx={
                    {
                        color: '#f4ce5c'
                    }
                } />
            </Box>
        </Box>
    )
}

export default PromotionMain;