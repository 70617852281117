import { Box, CircularProgress, Typography } from "@mui/material";

const LoadingPage = () => {
    return (
        <Box sx={
            {
                width: '100%',
                height: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                backgroundColor: 'rgba(0, 0, 0, .5)',
                zIndex: 100000,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center'
            }
        }>
            <CircularProgress size={30} sx={
                {
                    color: '#f4ce5c'
                }
            } />
        </Box>
    )
}

export default LoadingPage;